<script setup>
import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"
import HeaderComponent from './HeaderComponent.vue';
import HorizontalComponent from './HorizontalComponent.vue';
import SidebarComponent from './SidebarComponent.vue';
import RightSidebarComponent from './RightSidebarComponent.vue';
</script>

<template>
  <div id="layout-wrapper">
    <HeaderComponent></HeaderComponent>
    <HorizontalComponent></HorizontalComponent>
    <SidebarComponent></SidebarComponent>
  </div>
  <RightSidebarComponent></RightSidebarComponent>
  <div class="main-content">
        <div class="page-content">
            <div class="container-fluid">   
              <router-view></router-view> <!-- Este es donde las rutas se inyectan -->
            </div> <!-- container-fluid -->
        </div>
    </div>    
</template>
<script>

import { onMounted } from 'vue';
import eva from 'eva-icons';

onMounted(() => {
  import('@/assets/js/app.js')
    .then(() => {
      console.log('app.js cargado después de que los componentes hijos estén montados');
    })
    .catch((err) => console.error('Error cargando app.js:', err));
    
    eva.replace()
    .then(() => {
      console.log('eva cargado después de que los componentes hijos estén montados');
    })
    .catch((err) => console.error('Error cargando eva:', err));

});

export default {
  components: {
    HorizontalComponent,
    HeaderComponent,
    SidebarComponent,
    RightSidebarComponent,
  },
  data() {
    return {
      showRightSidebar: true,  // Este es solo un ejemplo de cómo podrías manejar el sidebar
    };
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/scss/app.scss';

</style>
