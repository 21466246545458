
import { createApp } from 'vue';
import App from './App.vue';
import { createHead } from '@vueuse/head';
import router from "./router";
// Importar FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//google
import VueGtag from 'vue-gtag-next';

// Importar TODOS los íconos de un paquete (ejemplo: Free Solid Icons)
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas) // Agregar todos los íconos de 'fas' a la librería

const app = createApp(App);


// Inicializar el meta manager
const head = createHead(); 

app.use(VueGtag, {
    property: {
      id: 'G-7XQH913X70',
    },
  }, router);
  
app.use(head);
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app');

