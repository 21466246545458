<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css'; // Importa los estilos básicos de Swiper
import 'swiper/css/navigation'; // Opcional: estilos para navegación
import 'swiper/css/pagination'; // Opcional: estilos para paginación

</script>
<template>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-4">
                            <div class="product-detail mt-3" dir="ltr">
                                <Swiper
                                    :modules="[Navigation, Pagination]"
                                    :navigation="true"
                                    :pagination="{ clickable: true }"
                                    :spaceBetween="10"
                                    :slidesPerView="1"
                                >
                                    <SwiperSlide><img src="@/img/map.webp" alt="Producto" class="img-fluid rounded" /></SwiperSlide>
                                    <SwiperSlide><img src="@/img/map.webp" alt="Producto" class="img-fluid rounded" /></SwiperSlide>
                                    <SwiperSlide><img src="@/img/map.webp" alt="Producto" class="img-fluid rounded" /></SwiperSlide>
                                </Swiper>                                   
                            </div>
                        </div>
                        <div class="col-xl-8">
                            <div class="mt-3 mt-xl-3 ps-xl-5">
                                <h4 class="font-size-20 mb-3">Facturacion Electronica</h4>

                                <p class="text-muted mb-0">
                                    <font-awesome-icon :icon="['fas', 'star']" />
                                    <font-awesome-icon :icon="['fas', 'star']" />
                                    <font-awesome-icon :icon="['fas', 'star']" />
                                    <font-awesome-icon :icon="['fas', 'star']" />
                                    <font-awesome-icon :icon="['fas', 'star-half-stroke']" />
                                </p>

                                <div class="text-muted mt-2">
                                    <span class="badge bg-success font-size-14 me-1"><i class="mdi mdi-star"></i> 4.4</span> 55 empresas facturan electronicamente con nosotros
                                </div>

                                <h3 class="mb-0 mt-4"><span class="text-muted me-2"><del class="font-size-22 fw-normal">$500</del></span><b>$450</b></h3>

                                <p class="mt-4 text-muted">La facturación que necesitas, soluciones hechas a tu medida</p>

                                <div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mt-3">
                                                <h5 class="font-size-14">Servicios :</h5>
                                                <ul class="list-unstyled ps-0 mb-0 mt-3">
                                                    <li><p class="text-muted mb-1 text-truncate"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Documentos Soporte </p></li>
                                                    <li><p class="text-muted mb-1 text-truncate"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Documentos Equivalentes</p></li>
                                                    <li><p class="text-muted mb-1 text-truncate"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Nomina Electronica</p></li>
                                                    <li><p class="text-muted mb-0 text-truncate"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Factura Electronica</p></li>
                                                    <li><p class="text-muted mb-1 text-truncate"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Encomiendas RNDC</p></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="mt-3">
                                                <h5 class="font-size-14">Especificaciones :</h5>
                                                <ul class="list-unstyled ps-0 mb-0 mt-3">
                                                    <li><p class="text-muted mb-1 text-truncate"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Sin Limite de Envios de Facturas </p></li>
                                                    <li><p class="text-muted mb-0 text-truncate"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Asesoria personalizada </p></li>
                                                </ul>
                                            </div>
                                            <div class="mt-3">
                                                <h5 class="font-size-14">Cotizacion Personalizada :</h5>

                                                <div class="d-inline-flex mt-2">
                                                    <div class="input-group mb-3">
                                                        <input type="text" class="form-control" placeholder="Correo Electronico">
                                                        <button class="btn btn-primary" type="button">Solicitar Cotizacion</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-6 col-sm-8">
                                            <div class="product-desc-color mt-3">
                                                <h5 class="font-size-14">Colors :</h5>
                                                <ul class="list-inline mt-3">
                                                    <li class="list-inline-item">
                                                        <i class="mdi mdi-circle font-size-18 text-dark"></i>
                                                    </li>
                                                    <li class="list-inline-item">
                                                        <i class="mdi mdi-circle font-size-18 text-success"></i>
                                                    </li>
                                                    <li class="list-inline-item">
                                                        <i class="mdi mdi-circle font-size-18 text-primary"></i>
                                                    </li>
                                                    
                                                    <li class="list-inline-item">
                                                        <a href="#" class="text-primary border-0 p-1">
                                                            2 + Colors
                                                        </a>
                                                    </li>
                                                </ul>
                                                
                                            </div>


                                            <div class="row text-center mt-4 pt-1">
                                                <div class="col-sm-6">
                                                    <div class="d-grid">
                                                        <button type="button" class="btn btn-primary waves-effect waves-light mt-2 me-1">
                                                            <i class="bx bx-cart me-2"></i> Add to cart
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="d-grid">
                                                        <button type="button" class="btn btn-light waves-effect  mt-2 waves-light">
                                                            <i class="bx bx-shopping-bag me-2"></i>Buy now
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->

                    <div class="row">
                        <div class="col-8">
                            <div class="mt-4 pt-3">
                                <h5 class="font-size-14 mb-3">Reseñas del Sistema: </h5>
                                <div class="text-muted mb-3">
                                    <span class="badge bg-success font-size-14 me-1"><i class="mdi mdi-star"></i> 4.5</span> 189 Reseñas
                                </div>
                                <div class="border py-4 rounded">
                                    <div class="px-4" data-simplebar style="max-height: 360px;">
                                        <div class="border-bottom pb-3">
                                            <p class="float-sm-end text-muted font-size-13">15 Febrero, 2024</p>
                                            <div class="badge bg-success mb-2"><i class="mdi mdi-star"></i> 4.7</div>
                                            <p class="text-muted mb-4">El sistema de facturación electrónica es excelente, cumple con todas las regulaciones de la DIAN y es muy fácil de usar. Me ha permitido automatizar todo mi proceso de facturación sin problemas.</p>
                                            <div class="d-flex align-items-start">
                                                <div class="flex-grow-1">
                                                    <div class="d-flex">
                                                        <img src="images/users/avatar-1.jpg" class="avatar-sm rounded-circle" alt="">
                                                        <div class="flex-1 ms-2 ps-1">
                                                            <h5 class="font-size-16 mb-0">Andrés López</h5>
                                                            <p class="text-muted mb-0 mt-1">Contador, 58 Reseñas</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="border-bottom py-3">
                                            <p class="float-sm-end text-muted font-size-13">10 Febrero, 2024</p>
                                            <div class="badge bg-success mb-2"><i class="mdi mdi-star"></i> 4.5</div>
                                            <p class="text-muted mb-4">Usamos la nómina electrónica y nos ha facilitado la generación y envío de los documentos a la DIAN. El soporte técnico también es excelente.</p>
                                            <div class="d-flex align-items-start">
                                                <div class="flex-grow-1">
                                                    <div class="d-flex">
                                                        <img src="images/users/avatar-2.jpg" class="avatar-sm rounded-circle" alt="">
                                                        <div class="flex-1 ms-2 ps-1">
                                                            <h5 class="font-size-16 mb-0">María Fernández</h5>
                                                            <p class="text-muted mb-0 mt-1">Gerente, 72 Reseñas</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>

                                    <div class="px-4 mt-2">
                                        <div class="border rounded mt-4">
                                            <form action="#">
                                                <div class="px-2 py-1 bg-light">
                                                    <div class="btn-group" role="group">
                                                        <button type="button" class="btn btn-sm btn-link text-dark text-decoration-none"><i class="bx bx-link"></i></button>
                                                        <button type="button" class="btn btn-sm btn-link text-dark text-decoration-none"><i class="bx bx-smile"></i></button>
                                                        <button type="button" class="btn btn-sm btn-link text-dark text-decoration-none"><i class="bx bx-at"></i></button>
                                                    </div>
                                                </div>
                                                <textarea rows="3" class="form-control border-0 resize-none" placeholder="Escribe tu reseña sobre facturación, nómina o documentos..."></textarea>
                                            </form>
                                        </div>

                                        <div class="text-end mt-3">
                                            <button type="button" class="btn btn-success w-sm text-truncate ms-2"> Enviar <i class="bx bx-send ms-2 align-middle"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="product-desc mt-4 pt-3">
                                <h5 class="font-size-14 mb-3">Product description: </h5>
                                <ul class="nav nav-tabs nav-tabs-custom" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link" id="desc-tab" data-bs-toggle="tab" href="#desc" role="tab">Description</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link active" id="specifi-tab" data-bs-toggle="tab" href="#specifi" role="tab">Specifications</a>
                                    </li>
                                </ul>
                                <div class="tab-content border border-top-0 p-4">
                                    <div class="tab-pane fade" id="desc" role="tabpanel">
                                        <div class="row">
                                            <div class="col-sm-3 col-md-3">
                                                <div>
                                                    <img src="images/product/img-3.jpg" alt="" class="img-fluid mx-auto rounded d-block">
                                                </div>
                                            </div>
                                            <div class="col-sm-9 col-md-9">
                                                <div class="text-muted p-2">
                                                    <p>If several languages coalesce, the grammar of the resulting language is more simple.</p>
                                                    <p>Everyone realizes a new common language would be desirable, one could refuse to pay expensive translators.</p>
                                                    <p>It will be as simple as occidental in fact.</p>

                                                    <div>
                                                        <ul class="list-unstyled product-desc-list text-body">
                                                            <li><i class="mdi mdi-circle-medium me-1 align-middle text-primary"></i> Sed ut perspiciatis omnis iste</li>
                                                            <li><i class="mdi mdi-circle-medium me-1 align-middle text-primary"></i> Neque porro quisquam est</li>
                                                            <li><i class="mdi mdi-circle-medium me-1 align-middle text-primary"></i> Quis autem vel eum iure</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show active" id="specifi" role="tabpanel">
                                        <div class="table-responsive">
                                            <table class="table table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" style="width: 50%;">Category :</th>
                                                        <td>Phone</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Brand :</th>
                                                        <td>Iphone</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Color :</th>
                                                        <td>Dark</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Quality :</th>
                                                        <td>High</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Material :</th>
                                                        <td>Metal</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 pt-3">
                        
                    </div>                                
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
    import "bootstrap/dist/css/bootstrap.min.css"
    import "bootstrap"

  export default {
    name: 'ImageComponent',
  };



  </script>
  