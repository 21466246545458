<template>
  <header id="page-topbar" class="isvertical-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="/assets/images/logo-dark-sm.png" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img src="/assets/images/logo-dark-sm.png" alt="" height="22">
            </span>
          </a>
          <a href="/" class="logo logo-light">
            <span class="logo-lg">
              <img src="/assets/images/logo-light.png" alt="" height="22">
            </span>
            <span class="logo-sm">
              <img src="/assets/images/logo-light-sm.png" alt="" height="22">
            </span>
          </a>
        </div>

        <button type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn topnav-hamburger" @click="toggleSidebar">
          <span class="hamburger-icon open">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>

        <div class="d-none d-sm-block ms-3 align-self-center">
          <h4 class="page-title">{{ title }}</h4>
        </div>
      </div>

      <div class="d-flex">
        <!-- Search Dropdown -->
        <div class="dropdown">
          <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="icon-sm" data-eva="search-outline"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-md p-0">
            <form class="p-2">
              <div class="search-box">
                <div class="position-relative">
                  <input type="text" class="form-control bg-light border-0" placeholder="Search...">
                  <i class="search-icon" data-eva="search-outline" data-eva-height="26" data-eva-width="26"></i>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Language Switcher -->
        <div class="dropdown d-inline-block language-switch">
          <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="header-lang-img" src="/assets/images/flags/us.jpg" alt="Header Language" height="16">
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <a href="javascript:void(0);" class="dropdown-item notify-item language" data-lang="eng">
              <img src="/assets/images/flags/us.jpg" alt="user-image" class="me-1" height="12">
              <span class="align-middle">English</span>
            </a>
          </div>
        </div>
        <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item noti-icon" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="icon-sm" data-eva="bell-outline"></i>
            <span class="noti-dot bg-danger rounded-pill">4</span>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 font-size-15">Notifications</h5>
                </div>
                <div class="col-auto">
                  <a href="javascript:void(0);" class="small fw-semibold text-decoration-underline">Mark all as read</a>
                </div>
              </div>
            </div>
            <div data-simplebar style="max-height: 250px;">
            </div>
          </div>
        </div>
        <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item user text-start d-flex align-items-center" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="rounded-circle header-profile-user" src="/assets/images/users/avatar-1.jpg" alt="Header Avatar">
          </button>
          <div class="dropdown-menu dropdown-menu-end pt-0">
            <div class="p-3 border-bottom">
              <h6 class="mb-0">{{ user.username }}</h6>
              <p class="mb-0 font-size-11 text-muted">{{ user.email }}</p>
            </div>
            <a class="dropdown-item" href="/profile">Profile</a>
            <a class="dropdown-item" href="/settings">Settings</a>
            <a class="dropdown-item" href="/logout">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      title: 'Dashboard', // This can be dynamic based on the page
      user: {
        username: 'John Doe',
        email: 'john.doe@example.com'
      }
    };
  },
  methods: {
    toggleSidebar() {
      // Logic to toggle the sidebar (you may need to interact with a store or global state)
      console.log('Sidebar toggled!');
    }
  }
};
</script>

<style scoped>
/* Custom styling for the header */
</style>
