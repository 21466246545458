import { createRouter, createWebHistory } from "vue-router";
import ImageComponent from "@/Templates/ImageComponent.vue";
import MapComponent from "@/Templates/MapComponent.vue";
//pages
import HomeComponent from "@/pages/home.vue";

import TeletiqueteComponent from "@/pages/allies/Teletiquete.vue";

import BillComponent from "@/pages/products/Bill.vue";
import AccountingComponent from "@/pages/products/Accounting.vue";
import EcommerceComponent from "@/pages/products/Ecommerce.vue";
import FixedassetsComponent from "@/pages/products/Fixedassets.vue";
import PayrollComponent from "@/pages/products/Payroll.vue";
import ParcelComponent from "@/pages/products/Parcel.vue";
import ParkingComponent from "@/pages/products/Parking.vue";
import HotelComponent from "@/pages/products/Hotel.vue";
import MaintenanceComponent from "@/pages/products/Maintenance.vue";
import MarketingComponent from "@/pages/products/Marketing.vue";

const routes = [
  { path: "/", component: HomeComponent },
  //
  { path: "/about", component: ImageComponent },
  { path: "/map", component: MapComponent },
  //product pages
  { path: "/teletiquete", component: TeletiqueteComponent },
  //
  { path: "/facturacion", component: BillComponent },
  { path: "/contabilidad", component: AccountingComponent },
  { path: "/ecommerce", component: EcommerceComponent },
  { path: "/activosfijos", component: FixedassetsComponent },
  { path: "/nomina", component: PayrollComponent },
  { path: "/encomiendas", component: ParcelComponent },
  { path: "/parqueadero", component: ParkingComponent },
  { path: "/hotel", component: HotelComponent },
  { path: "/mantenimiento", component: MaintenanceComponent },
  { path: "/marketing", component: MarketingComponent },
  { path: "/nomina", component: BillComponent },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
