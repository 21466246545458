<template>
  <header class="ishorizontal-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img :src="logoDarkSm" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img :src="logoDark" alt="" height="22">
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img :src="logoLightSm" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img :src="logoLight" alt="" height="22">
            </span>
          </router-link>
        </div>

        <button type="button" class="btn btn-sm px-3 font-size-26 d-lg-none header-item" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
          <font-awesome-icon :icon="['fas', 'bars']" />
        </button>

        <div class="d-none d-sm-block ms-2 align-self-center">
          <h4 class="page-title">{{ title }}</h4>
        </div>
      </div>

      <div class="d-flex">
        <div class="dropdown">
          <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <font-awesome-icon :icon="['fas', 'search']" />
          </button>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-md p-0">
            <form class="p-2">
              <div class="search-box">
                <div class="position-relative">
                  <input type="text" class="form-control bg-light border-0" placeholder="Search...">
                  <font-awesome-icon :icon="['fas', 'search']" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="topnav">
      <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
          <div class="collapse navbar-collapse" id="topnav-menu-content">
            <ul class="navbar-nav">

              <li class="nav-item dropdown" v-for="item in menu" :key="item.id">
                <a class="nav-link dropdown-toggle arrow-none" :href="item.url" :id="'topnav-'+item.alias" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <font-awesome-icon :icon="['fas', item.icon]" />
                  <span data-key="t-apps">{{ item.name }}</span>
                </a>
                <div v-if="item.children && item.children.length" class="dropdown-menu" :aria-labelledby="'topnav-'+item.alias">
                  <menu-item v-for="subItem in item.children" :key="subItem.id" :subItem="subItem"></menu-item>
                </div>
              </li>                                          
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import MenuItem from './MenuItem.vue';

document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll('.dropdown-menu .dropdown-toggle').forEach(function (element) {
        element.addEventListener('click', function (e) {
            if (window.innerWidth < 992) { // Solo para dispositivos móviles
                e.preventDefault();
                let nextMenu = this.nextElementSibling;

                if (nextMenu && nextMenu.classList.contains('dropdown-menu')) {
                    // Cerrar otros submenús dentro del mismo nivel
                    this.parentElement.parentElement.querySelectorAll('.dropdown-menu.show').forEach(function (openSubMenu) {
                        if (openSubMenu !== nextMenu) {
                            openSubMenu.classList.remove('show');
                        }
                    });

                    // Alternar el estado del submenú actual
                    nextMenu.classList.toggle('show');

                    // Evita que el menú de 'Apps' se cierre al abrir un submenú
                    e.stopPropagation();
                }
            }
        });
    });

    // Cerrar todos los menús (incluyendo el principal y submenús) al hacer clic fuera
    document.addEventListener('click', function (e) {
        if (window.innerWidth < 992) {
            // Cierra submenús dentro de cualquier dropdown
            document.querySelectorAll('.dropdown-menu .dropdown-menu').forEach(function (menu) {
                if (!menu.contains(e.target) && !menu.previousElementSibling.contains(e.target)) {
                    menu.classList.remove('show');
                }
            });

            // Cierra el menú principal (Apps) si no se hace clic dentro de él
            let mainMenu = document.querySelector('#topnav-pages');
            if (mainMenu && !mainMenu.contains(e.target)) {
                let dropdown = mainMenu.nextElementSibling;
                if (dropdown && dropdown.classList.contains('show')) {
                    dropdown.classList.remove('show');
                }
            }
        }
    });
});




export default {
  name: 'HeaderComponent',
  components: {
    MenuItem // Registra el componente en la sección de componentes
  },
  data() {
    return {
      title: 'Silog - Servicios Empresariales', // Puedes establecer el título aquí
      logoDarkSm: require('@/img/login-img.png'),
      logoDark: require('@/img/login-img.png'),
      logoLightSm: require('@/img/login-img.png'),
      logoLight: require('@/img/login-img.png'),
      menu: [],         // Aquí se almacenará el menú
      menuLoaded: false // Indicador de carga
    };
  },
  async mounted() {
    try {
      const response = await fetch('https://tiquetevirtual.com/api/v1/menu');
      console.log("response");
      console.log(response);
      const data = await response.json();
      console.log("data");
      console.log(response);      
      this.menu = data;
      this.menuLoaded = true;
    } catch (error) {
      console.error("Error al cargar el menú:", error);
      this.menuLoaded = true; // Aunque haya error, marcar como cargado para evitar bloqueos
    }
  }
};
</script>

<style scoped>

/* Agrega tus estilos personalizados aquí */
</style>
