<template>
<div class="col-xl-9">
    <div class="p-4">
        <div class="card mb-0">
                <div class="auth-full-page-content rounded d-flex p-3 my-2">
                    <div class="w-100">
                        <div class="d-flex flex-column h-100">
                            <div class="mb-4 mb-md-5">
                                <a href="" class="d-block auth-logo">
                                    <img src="" alt="" height="22" class="auth-logo-dark me-start">
                                    <img src="" alt="" height="22" class="auth-logo-light me-start">
                                </a>
                            </div>
                            <div class="auth-content my-auto">
                                <div class="text-center">
                                    <h5 class="mb-0">Register Account</h5>
                                    <p class="text-muted mt-2">Get your free Borex account now.</p>
                                </div>
                                <form class="mt-4 pt-2" action="" method="POST">

                                    <div class="mb-3">
                                        <label for="correo" class="form-label requiredField">Ingresa El Correo.<span class="asteriskField">*</span></label>
                                        <input class="form-control mb-3 textinput" type="email" name="correo" placeholder="Correo">
                                    </div>
                                    
                                    <div class="mb-3">
                                        <label for="usuario" class="form-label requiredField">Ingresa Sus Nombres.<span class="asteriskField">*</span></label>
                                        <input type="text" name="usuario" class="form-control mb-3 textinput" placeholder="Nombres">
                                    </div>

                                    <div class="mb-3">
                                        <label for="usuario" class="form-label requiredField">Ingresa Sus Apellidos.<span class="asteriskField">*</span></label>
                                        <input type="text" name="apellidos" class="form-control mb-3 textinput" placeholder="Apellidos">
                                    </div>


                                    <div class="mb-3">
                                        <label for="Identificacion" class="form-label requiredField">Ingresa el número de identificación<span class="asteriskField">*</span></label>
                                        <input type="text" name="identificacion" class="form-control mb-3 textinput" placeholder="Identificacion">
                                    </div>

                                    <div class="mb-3">
                                        <label for="contraseña" class="form-label requiredField">Ingresa la contraseña<span class="asteriskField">*</span></label>
                                        <input type="password" name="contraseña" class="form-control mb-3 textinput" placeholder="Contraseña">
                                    </div>
                                    
                                    <div class="mb-3">
                                        <label for="concontraseña" class="form-label requiredField">Confirma la contraseña<span class="asteriskField">*</span></label>
                                        <input type="password" name="confirm" class="form-control mb-3 textinput" placeholder="Confirma La Contraseña">
                                    </div>

                                    <div class="mb-4">
                                        <p class="mb-0">By registering you agree to the Borex <a href="" class="text-primary">Terms of Use</a></p>
                                    </div>

                                    <div class="mb-3">
                                        <input type="submit" value="Registrar" class="btn btn-primary w-100 waves-effect waves-light">
                                    </div>

                                </form>

                                <div class="mt-4 pt-3 text-center">
                                    <div class="signin-other-title">
                                        <h5 class="font-size-14 mb-4 text-muted fw-medium">- Or you can join with -</h5>
                                    </div>

                                    <div class="d-flex gap-2">

                                <div class="mt-4 pt-3 text-center">
                                    <p class="text-muted mb-0">Already have an account ? <a href="" class="text-primary fw-semibold"> Login </a> </p>
                                </div>
                            </div>
                            <div class="mt-4 text-center">
                                <p class="mb-0">by Themesbrand</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>
  
  <script>
    import "bootstrap/dist/css/bootstrap.min.css"
    import "bootstrap"

  export default {
    name: 'ImageComponent',
  };



  </script>
  