<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
              <img src="@/img/map.webp" alt="Workplace" usemap="#workmap" width="930" height="723">

              <map name="workmap">
                <area shape="rect" coords="34,44,270,350" alt="Computer" href="computer.htm">
                <area shape="rect" coords="290,172,333,250" alt="Phone" href="phone.htm">
                <area shape="circle" coords="337,300,44" alt="Cup of coffee" href="coffee.htm">
              </map>
          </div>
        </div>
      </div>              
    </div>
  </div>   
</template>
                    
<script>
    export default {
        name: 'MapComponent',
    };
</script>
  