<template>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row" style="height: 700px;">
              <!-- Mapa con la referencia correcta -->
              <l-map :zoom="zoom" :center="center" style="width: 100%; height: 700px;" ref="map">
                <l-tile-layer :url="tileUrl" :attribution="attribution" />
              </l-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { LMap, LTileLayer } from 'vue3-leaflet';
  import 'leaflet/dist/leaflet.css'; // Importar los estilos de Leaflet
  import L from 'leaflet'; // Importar Leaflet
  import 'leaflet.heat'; // Importar el plugin de Heatmap
  
  export default {
    name: 'AccountingComponent',
    components: {
      LMap,
      LTileLayer,
    },
    data() {
      return {
        // Coordenadas y zoom iniciales
        center: [4.5709, -74.2973], // Centro de Colombia
        zoom: 6,
        tileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; OpenStreetMap contributors',
      };
    },
    mounted() {
      this.$nextTick(() => {
        // Accedemos al objeto del mapa usando `this.$refs.map.map`
        const map = this.$refs.map.map;
  
        // Datos ficticios para el mapa de calor (por ejemplo, ubicaciones de interés en Colombia)
        const heatData = [
            [4.60971, -74.08175, 0.8],  // Bogotá
            [6.25184, -75.56359, 0.5],  // Medellín
            [10.965, -74.798, 0.7],     // Barranquilla
            [3.43722, -76.5225, 0.6],   // Cali
            [7.1281, -73.1184, 0.9],    // Bucaramanga
            [5.0689, -75.5152, 0.4],    // Manizales
            [3.55227, -76.19999, 0.5],
            [4.12345, -73.5567, 0.6],
            [8.08015, -75.484, 0.4],
            [5.2406, -75.625, 0.8],
            [9.1825, -75.826, 0.6],
            [6.4771, -75.848, 0.7],
            [10.4478, -74.1772, 0.5],
            [9.2920, -75.5322, 0.3],
            [4.3800, -73.696, 0.6],
            [4.6224, -74.2906, 0.7],
            [6.5514, -75.2446, 0.5],
            [8.4546, -76.6167, 0.9],
            [4.7296, -75.8342, 0.6],
            [7.0716, -73.8394, 0.5],
            [5.0000, -75.0893, 0.8],
            [6.3152, -76.1565, 0.6],
            [8.2420, -75.6475, 0.5],
            [5.9654, -75.3021, 0.4],
            [6.3562, -75.4282, 0.7],
            [10.3857, -75.6930, 0.8],
            [6.9643, -75.8705, 0.5],
            [7.2931, -73.9568, 0.6],
            [4.1781, -73.8985, 0.7],
            [5.8090, -75.0842, 0.9],
            [8.6233, -75.4371, 0.4],
            [9.1057, -74.6697, 0.6],
            [4.5698, -75.2254, 0.5],
            [7.9634, -73.2689, 0.7],
            [6.2958, -75.8292, 0.5],
            [8.7032, -74.8131, 0.6],
            [9.4214, -75.5471, 0.4],
            [3.9754, -76.0646, 0.6],
            [5.4179, -75.1234, 0.7],
            [10.1698, -74.9315, 0.5],
            [7.3141, -73.4939, 0.8],
            [4.3051, -74.0992, 0.7],
            [6.1680, -75.6871, 0.6],
            [9.4070, -74.2134, 0.5],
            [6.9022, -75.6245, 0.8],
            [8.0224, -74.9642, 0.6],
            [5.7889, -75.4425, 0.7],
            [6.5101, -75.7589, 0.9],
            [7.7364, -73.1184, 0.5],
            [4.6127, -73.8253, 0.6],
            [8.2329, -75.9152, 0.7],
            [5.1114, -75.2021, 0.8],
            [6.5722, -75.8706, 0.6],
            [4.4970, -75.4703, 0.7],
            [6.1555, -75.5973, 0.5],
            [9.7349, -74.9247, 0.4],
            [5.6814, -75.9051, 0.6],
            [6.8337, -75.2935, 0.7],
            [4.2141, -75.2905, 0.9],
            [8.6592, -75.3721, 0.5],
            [7.5053, -73.5721, 0.7],
            [5.3542, -75.5641, 0.8],
            [6.4113, -75.3762, 0.5],
            [7.0860, -73.2651, 0.6],
            [6.2309, -75.3468, 0.7],
            [5.6004, -75.7592, 0.4],
            [9.1442, -74.2678, 0.5],
            [6.5678, -75.2015, 0.7],
            [7.8297, -73.6511, 0.6],
            [8.1520, -75.9123, 0.8],
            [5.4825, -75.6983, 0.7],
            [6.1419, -75.6745, 0.5],
            [4.7783, -74.8122, 0.6],
            [8.3923, -75.3270, 0.7],
            [7.5421, -73.8774, 0.5],
            [6.2879, -75.5613, 0.4],
            [9.7202, -74.8982, 0.8],
            [4.3665, -74.1362, 0.9],
            [6.7239, -75.3285, 0.6],
            [7.0149, -73.7592, 0.7],
            [9.0815, -75.0168, 0.6],
            [5.6738, -75.5073, 0.7],
            [8.1487, -74.6702, 0.6],
            [6.9702, -75.6287, 0.9],
            [7.0531, -73.2889, 0.5],
            [6.5311, -75.5511, 0.6],
            [5.3891, -75.6823, 0.7],
            [7.6274, -73.5230, 0.5],
            [5.2041, -75.8843, 0.6],
            [9.1928, -74.6432, 0.4],
            [8.2042, -75.2325, 0.5],
            [7.4348, -73.9273, 0.6],
            [5.8120, -75.1593, 0.7],
            [9.5060, -75.2789, 0.8],
            [6.9700, -75.2307, 0.5],
            [7.2040, -73.8540, 0.6],
            [9.7458, -74.8504, 0.7],
            [4.1550, -74.5360, 0.8],
            [5.5013, -75.7376, 0.9],
            [9.0136, -74.5829, 0.5],
            [8.3405, -75.3145, 0.7],
            [6.6797, -75.5001, 0.5],
            [7.1731, -73.9983, 0.6],
            [9.5674, -74.9374, 0.7],
            [5.8946, -75.8923, 0.4],  
            [5.5321, -73.3677, 1.0],  // Tunja (capital de Boyacá)
            [5.5692, -73.3589, 1.0],  // Tunja
            [5.4803, -73.3350, 1.0],  // Tunja
            [5.3157, -73.2751, 1.0],  // Sogamoso
            [5.5152, -73.1711, 1.0],  // Duitama
            [5.6800, -73.2252, 1.0],  // Paipa
            [5.3070, -73.5281, 1.0],  // Nobsa
            [5.1005, -73.3902, 1.0],  // Chiquinquirá
            [5.5412, -73.3095, 1.0],  // Socha
            [5.3223, -73.5601, 1.0],  // Tibasosa
            [5.5420, -73.5120, 1.0],  // Arcabuco
            [5.3783, -73.3517, 1.0],  // San Gil
            [5.6099, -73.3577, 1.0],  // Guateque
            [5.2297, -73.5267, 1.0],  // Ventaquemada
            [5.5937, -73.1564, 1.0],  // Chiquinquirá
            [5.6731, -73.2635, 1.0],  // Paya
            [5.6355, -73.4864, 1.0],  // Cuitiva
            [5.5492, -73.4591, 1.0],  // Gachantivá
            [5.3621, -73.5120, 1.0],  // Tuta
            [5.4015, -73.6715, 1.0],  // Ráquira
            [5.5841, -73.2753, 1.0],  // Oicatá
            [5.3931, -73.4691, 1.0],  // Cómbita
            [5.4911, -73.3124, 1.0],  // Siachoque
            [5.2348, -73.3170, 1.0],  // Samacá
            [5.4320, -73.3136, 1.0],  // Santa Rosa de Viterbo
            [5.3981, -73.5680, 1.0],  // Sogamoso
            [5.4501, -73.2562, 1.0],  // Firavitoba
            [5.4990, -73.2221, 1.0],  // Tota
            [5.4478, -73.4324, 1.0],  // Boyacá
            [5.3775, -73.2767, 1.0],  // Iza
            [5.3629, -73.2017, 1.0],  // La Uvita
            [5.5383, -73.2885, 1.0],  // Coper
            [5.4842, -73.4674, 1.0],  // San Miguel de Sema
            [5.4189, -73.2142, 1.0],  // Muzo
            [5.5398, -73.5512, 1.0],  // Santa María
            [5.3190, -73.3569, 1.0],  // Chíquiza
            [5.4214, -73.3031, 1.0],  // Toca
            [5.3369, -73.4897, 1.0],  // Guayatá
            [5.2955, -73.3279, 1.0],  // Boavita
            [5.3039, -73.5123, 1.0],  // La Victoria
            [5.4742, -73.4730, 1.0],  // Otanche
            [5.5263, -73.3814, 1.0],  // Susacón
            [5.6244, -73.4645, 1.0],  // El Cocuy
            [5.4329, -73.3637, 1.0],  // Chita
            [5.4884, -73.3228, 1.0],  // Chitaraque
            [5.6000, -73.4213, 1.0],  // Tinjacá
            [5.3125, -73.3063, 1.0],  // Zetaquira
            [5.4709, -73.3157, 1.0],  // La Uvita
            [5.4100, -73.4736, 1.0],  // Tóez
            [5.5214, -73.2436, 1.0],  // Boavita
            [5.3703, -73.2321, 1.0],  // Sáchica
            [5.6034, -73.5638, 1.0],  // Socotá
            [5.3334, -73.4780, 1.0],  // Garagoa
            [5.4604, -73.3320, 1.0],  // Monguí
            [5.3887, -73.5580, 1.0],  // Pajarito
            [5.4746, -73.4969, 1.0],  // Arcabuco
            [5.3154, -73.5251, 1.0],  // Tópaga
            [5.3539, -73.3700, 1.0],  // Jenesano
            [5.2337, -73.5436, 1.0],  // Miraflores
            [5.6110, -73.5067, 1.0],  // Pisba
            [5.4661, -73.2555, 1.0],  // Sáchica
            [5.4018, -73.4534, 1.0],  // Berbeo
            [5.4536, -73.4967, 1.0],  // Chíquiza
            [5.5617, -73.2179, 1.0],  // Candelaria
            [5.5061, -73.4578, 1.0],  // Cuítiva
            [5.4532, -73.4195, 1.0],  // Tipacoque
            [5.5056, -73.4809, 1.0],  // Tunja
            [5.3202, -73.5156, 1.0],  // Caldas
            [5.6000, -73.4730, 1.0],  // Garagoa
            [5.4744, -73.3301, 1.0],  // Gámeza
            [5.4336, -73.4661, 1.0],  // Villa de Leyva
            [5.5303, -73.3312, 1.0],  // Cabañas
            [5.4962, -73.4740, 1.0],  // Saboyá
            [5.2902, -73.3140, 1.0],  // San Mateo
            [5.5497, -73.5301, 1.0],  // Miraflores
            [5.4741, -73.3657, 1.0],  // Berbeo
            [5.4031, -73.4577, 1.0],  // Tinjacá
            [5.3064, -73.3478, 1.0],  // Moniquirá
            [5.5200, -73.3810, 1.0],  // San Eduardo
            [5.5506, -73.3184, 1.0],  // Sutamarchán
            [5.4651, -73.3024, 1.0],  // Paipa
            [5.4210, -73.4084, 1.0],  // Toca
            ];

        // Crear el mapa de calor y agregarlo al mapa
        L.heatLayer(heatData, { radius: 25, blur: 15, maxZoom: 17 }).addTo(map);
      });
    },
  };
  </script>
  
  <style scoped>
  #map {
    width: 100%;
    height: 500px;
  }
  </style>
  