<template>
    <div id="app">
      <BaseComponent/>
    </div>
  </template>
  
  <script>  
  import BaseComponent from '@/partials/BaseComponent.vue';

  export default {
    name: 'App',
    components: {
      BaseComponent
    }
  };
  </script>
  