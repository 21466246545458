<template>
    <div class="dropdown">
        <a :class="{'dropdown-item dropdown-toggle arrow-none': subItem.children && subItem.children.length,'dropdown-item ': !(subItem.children && subItem.children.length)}" :href="subItem.url" :id="'topnav-'+subItem.alias" role="button">
            <span :data-key="'t-'+subItem.alias">{{ subItem.name }}</span> 
        </a>
        <div v-if="subItem.children && subItem.children.length" class="dropdown-menu" :aria-labelledby="'topnav-'+subItem.alias">
            <menu-item  v-for="child in subItem.children" :key="child.id" :subItem="child"></menu-item>
        </div>
    </div>   
</template>

<script>
export default {    
  name: 'MenuItem',
  props: {
    subItem: Object
  },
};
</script>
